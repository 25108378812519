.tabs {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
    background-color: #ffffff21;
    padding: 5px 0;
}
.tabs li:first-child {
    width: 15%;
    text-align: center;
    font-size: 13px;
}
.tabs li:nth-child(2) {
    width: 70%;
    text-align: center;
    font-size: 13px;
}
.tabs li:last-child {
    width: 15%;
    padding: 5px 0;
    text-align: center;
}
.tabs li:last-child > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.tabs li:last-child > div > span {
    margin-right: 7px;
    font-size: 13px;
    letter-spacing: 1px;
}

.matchsContainer {
    height: 100%;
    overflow: hidden auto;
}
.matchsContainer li {
    margin-bottom: 7px;
}
.matchsContainer li:last-child {
    margin-bottom: 0;
}

.leagueName {
    margin-bottom: 5px;
}
.leagueName span {
    font-size: 13px;
    color: var(--accent-color);
}
.matchsContainer li .teamsGroup {
    margin-bottom: 5px;
}
.matchsContainer li .teamsGroup:last-child {
    margin-bottom: 0;
}
.teamsGroup {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #ffffff33;
    border-radius: 5px;
}

.teamsGroup > div:first-child {
    width: 16%;
    padding: 5px 0;
    text-align: center;
    font-size: 11px;
    padding-left: 5px;
}
.teamsGroup > div:nth-child(2) {
    width: 69%;
    padding: 5px 0;
    text-align: start;
    font-size: 13px;
}
.teamsGroup > div:last-child {
    width: 15%;
    padding: 5px 0;
    text-align: center;
    cursor: pointer;
}

.homeTeam, .awayTeam {
    padding: 3px 0 3px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.homeTeam div, .awayTeam div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
}
.homeTeam img, .awayTeam img{
    width: 22px;
    margin-right: 7px;
}

@media (min-width: 200px) and (max-width: 350px) {
    .tabs li:first-child,.tabs li:nth-child(2),.tabs li:last-child > div > span {
        font-size: 11px;
    }
    .teamsGroup > div:first-child {
        font-size: 9px;
    }
    .teamsGroup > div:nth-child(2) {
        font-size: 12px;
    }
 }