.rulesContainer {
    position: relative;
    height: 100%;
}
.rulesContainer h1 {
    position: fixed;
    top: 0;
    max-width: 480px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #000626;
    font-size: 15px;
    text-align: center;
    font-weight: 400;
    padding: 5px 0;
}
.content {
    /* padding-bottom: 132px; */
    padding-top: 40px;
    padding-bottom: 200px;
}
.rulesFooter {
    position: fixed;
    bottom: 0;
    background-color: #000626;
    font-size: 14px;
    border-top: 1px solid var(--accent-color);
    padding: 5px 5px 10px 5px;
    max-width: 480px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-weight: 400;
    /* box-shadow: 0 2px 10px var(--accent-color); */
    text-align: center;
}
.rulesList li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.rulesList li p {
    margin-bottom: 10px;
    font-size: 13px;
}
.rulesList li h3 {
    /* color: var(--accent-color); */
    font-size: 14px;
    margin-bottom: 0;
    text-decoration: underline;
    font-weight: 400;
}