.typeCard {
    width: 33.33%;
    /* margin-bottom: 10px; */
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 6px 3px 0 3px;
}
.type {
    width: 100%;
    height: 100%;
    background-color: #d1870082;
    border-radius: 10px;
    color: #FFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 7px;
}

.typeImg {
    margin-bottom: 5px;
}
.typeName {
    font-size: 12px;
    /* color: #5b5b5b; */
    color: #FFF;
}
.playBtn {
    border-radius: 20px;
    background-color: var(--accent-color);
    border: 2px solid var(--accent-color);
    outline: none;
    cursor: pointer;
    color: #FFF;
    font-size: 11px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.playBtn:hover {
    background-color: #a66c00;
}

@media screen and (max-width: 309px) {
    .typeCard {
        width: 48.5%;
    }
}