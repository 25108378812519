.container {
    width: 100%;
    max-width: 480px;
    height: 100vh;
    background-color: #00000075;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
}

.imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imageContainer img {
    width: 90px;
    margin-bottom: 10px;
}
.imageContainer p {
    margin-bottom: 5px;
    font-size: 19px;
    text-align: center;
    color: tomato;
    font-weight: bolder;
    /* text-shadow: 2px 2px #FFF; */
}