.header {
    height: 60px;
    background-color: var(--secondary-color);
    position: fixed;
    width: 100%;
    max-width: 480px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}
.back {
    padding: 0;
    width: 40px;
    height: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    line-height: 46px;
    background-color: transparent;
    color: #FFF;
}
.backNormal {
    padding: 0;
    width: 60px;
    height: 40px;
    outline: none;
    border: none;
    cursor: pointer;
    line-height: 55px;
    text-align: center;
    background-color: #ffa500b8;
    color: #FFF;
    border-radius: 20px;
    margin-right: 10px;
}

.typeName {
    font-size: 14px;
}
.headerIconBtn {
    border: none;
    border-radius: 50%;
    background-color: transparent;
    color: #FFF;
    cursor: pointer;
}
.refreshIcons {
    transform: rotate(90deg) scaleX(-1);
}
.backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #00000066;
    z-index: 2;
    display: flex;
}
.sidebar {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #FFF;
    overflow: hidden;
}
.sidebarHeader {
    position: inherit;
    top: 0;
    height: 100px;
    background-color: #212121;
    width: 300px;
    overflow: hidden;
    display: flex;
    padding: 0 20px;
    align-items: center;
}
.userIcon {
    display: block;
    width: 35px;
    height: 35px;
    background-color: #FFF;
    border-radius: 25px;
    color: var(--secondary-color);
    text-align: center;
    line-height: 35px;
    font-size: 15px;
}

.userCode {
    font-size: 15px;
    margin-left: 15px;
}

.sidebarContent {
    padding-top: 100px;
    width: 300px;
    overflow: hidden;
}

.sidebarContent li {
    padding: 12px 20px;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.sidebarContent li:hover {
    background-color: #e6e6e6;
}

.rotateEff {
    animation: rotateAnimation 1s ease;
}

@keyframes rotateAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}