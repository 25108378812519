.footer {
    height: 85px;
    background-color: var(--secondary-color);
    position: fixed;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 10px;
}
.betAmount {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.betAmount p {
    font-size: 15px;
}