.banner {
    position: relative;
}
.captionContainer {
    width: 100%;
}

.banner-img {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    height: 220px;
    object-fit: cover;
}
.banner-preload {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d187010d;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .banner-img {
    height: 150px;
  }
}
.swiper-pagination {
    text-align: center;
}
.swiper-pagination-bullet {
    background-color: var(--secondary-color);
    transition: 0.5s;
}
.swiper-pagination-bullet-active {
    width: 40px;
    height: 10px;
    border-radius: 5px;
}
#hero {
    position: relative;
}
#hero:before {
    content: "";
    position: absolute;
    background-color: var(--main-color);
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}
 @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }
  .hero-waves {
    display: block;
    width: 100%;
    height: 70px;
    /* margin-top: 60px; */
    z-index: 5;
    position: relative;
  }
  
  .wave1 use {
    animation: move-forever1 10s linear infinite;
    animation-delay: -2s;
  }
  
  .wave2 use {
    animation: move-forever2 8s linear infinite;
    animation-delay: -2s;
  }
  
  .wave3 use {
    animation: move-forever3 6s linear infinite;
    animation-delay: -2s;
  }
  .banner-frame{
    height:40px;
    text-align: center;
    /* background: rgba(0, 0, 0, 0.4); */
    background-color: #ffffff21;
    color:var(--text-color);
    overflow: hidden;
    font-weight: 300;
    font-size: 12px;
    line-height: 40px;
    margin: 5px 0;
    border-radius: 10px;
    padding: 0 10px;
    user-select: none;
}

/*----------------------------------------------------------------------------- */
@media (min-width: 318px) and (max-width: 480px) {
   .banner-frame {
    font-size: 10px;
   }
}

  @keyframes move-forever1 {
    0% {
      transform: translate(85px, 0%);
    }
  
    100% {
      transform: translate(-90px, 0%);
    }
  }
  
  @keyframes move-forever2 {
    0% {
      transform: translate(-90px, 0%);
    }
  
    100% {
      transform: translate(85px, 0%);
    }
  }
  
  @keyframes move-forever3 {
    0% {
      transform: translate(-90px, 0%);
    }
  
    100% {
      transform: translate(85px, 0%);
    }
  }