.topBackground {
    height: 180px;
    width: 100%;
    position: relative;
    margin-bottom: 35px;
}
.bgImg {
    width: 100%;
    height: 100%;
    background: url('../../assets/images/other/profile.jpg') no-repeat center;
    background-size: cover;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
    position: relative;
}
.bgImg:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0006266e;
}
.profileImageContainer {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #FFF;
    position: absolute;
    bottom: -30px;
    left: 10px;
    border: 3px solid #000626;
    background: url('../../assets/images/other/football.png') no-repeat center;
    background-size: cover;
}
.profileListContainer li {
    margin-bottom: 11px;
}
.profileListContainer li:last-child {
    margin-bottom: 0;
}
.listTitle {
    font-size: 14px;
    margin-bottom: 2px;
}
.underline {
    height: 3px;
    width: 70px;
    background-color: var(--accent-color);
    border-radius: 2px;
    margin-bottom: 15px;
}
.listDesc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    /* background-color: #2f3950; */
    background-color: #d1870082;
    border-radius: 10px;
    cursor: pointer;
}
.listDesc div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.listDesc span {
    margin-left: 10px;
    font-size: 15px;
    letter-spacing: 0.2px;
    /* color: #ffffffcb; */
    color: #FFF;
}

.usercode {
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: bolder;
    border-bottom: 1px solid #2f3950;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.usercode > span {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 5px;
    font-weight: normal;
}

.usercode > span > span {
    color: var(--accent-color);
    text-decoration: underline;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.modalTitle {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
}
.types {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 20px;
}
.type {
    text-align: center;
    margin: 0 5px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;
}
.type img {
    width: 70px;
}
.type p {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 14px;
    letter-spacing: 0.3px;
}

.ok {
    font-size: 16px;
}