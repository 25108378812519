.container {
    width: 100%;
    max-width: 480px;
    height: 100vh;
    background-color: #00000075;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 2;
    /* padding: 20px; */
}

.content {
    height: 100%;
    overflow: hidden auto;
}
.authImg {
    width: 100%;
    border-radius: 0 0 30px 30px;
}
.title {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 0;
    font-weight: bolder;
    font-size: 20px;
}
.label {
    color: #FFF;
}
.input {
    padding: 10px 15px;
    font-size: 15px;
    background-color: #d187006b !important;
    border-color: transparent;
    color: #FFF;
    border-radius: 12px;
}
.input::-webkit-input-placeholder {
    color: #d1880097;  
}
.input::-moz-placeholder {
    color: #d1880097;  
}
.input:-ms-input-placeholder {
    color: #d1880097;  
}
.input::placeholder {
    color: #d1880097;  
}
.input:-ms-input-placeholder { 
    color: #d1880097;
}
.input::-ms-input-placeholder { 
    color: #d1880097;
}