.footer {
    height: 60px;
    /* background : linear-gradient(90deg, #FFA500 0%, #ffcc6d 100%); */
    /* background-color : #FFA500; */
    background-color: var(--secondary-color);
    position: fixed;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 10px 10px 0 0;
}
.tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
}
.tab li {
    width: 100%;
    height: 100%;
}
.tab li button {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    /* color: #bdbdbd; */
    color: #FFF;
}
.tab li button p {
    margin-bottom: 0;
    font-size: 10px;
}