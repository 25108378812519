.imgContainer {
    width: 100%;
    max-width: 480px;
    background: url('../../assets/images/other/field.jpg') center no-repeat;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 10px;
}

.maintenanceImgContainer {
    width: 270px;
    margin-bottom: -4px;
}
.maintenanceImgContainer img {
    width: 100%;
}
.maintainCard {
    margin-top: 10px;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    background-color: #d1870082;
    border-radius: 10px;
    color: #FFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.text {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    line-height: 33px;
}
.btnCenter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 30px;
}
.backHomeBtn {
    padding: 11px 30px;
    border-radius: 20px;
    background-color: var(--accent-color);
    border: 2px solid var(--accent-color);
    outline: none;
    cursor: pointer;
    color: #FFF;
    font-size: 16px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.backHomeBtn:hover {
    background-color: #a66c00;
}