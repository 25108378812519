.filterContainer {
    background-color: #ffffff21;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-right: 10px;
    position: absolute;
    top: 0;
    width: 100%;
}
.filterTypesGroup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.filterTypesGroup li {
    padding: 5px 10px;
    /* font-size: 13px; */
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.mainTypeActive {
    border-bottom: 2px solid var(--accent-color);
}
.moreBtnContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.moreFilterBtn {
    width: 28px;
    height: 28px;
    border: none;
    outline: none;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: #ffffff38;
    cursor: pointer;
}

.typesListContainer {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.typeList {
    width: 33.33%;
    padding: 5px;
}
.typeList .type {
    width: 100%;
    border-radius: 5px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
}
.subTypeActive {
    background-color: var(--accent-color);
    color: #FFF;
}
.subTypeInActive {
    background-color: #FFF;
    color: #333852;
}

.cardsContainer {
    background-color: #333852;
    height: 100%;
    padding: 10px 15px;
    overflow: hidden scroll;
}

.historyCard {
    margin-bottom: 15px;
    border-bottom: 1px solid #ffffff36;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 15px;
    font-size: 13px;
    cursor: pointer;
    color: #ffffffad;
}

.cardsContainer .historyCard:last-child {
    margin-bottom: 0;
    /* border-bottom: none; */
}

.historyDate {
    font-size: 12px;
    margin-top: 2px;
}
.amount {
    font-size: 15px;
}
.typeIconImg {
    width: 30px;
    margin-right: 10px;
}