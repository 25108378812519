.header {
    height: 60px;
    /* background : linear-gradient(90deg, #FFA500 0%, #ffcc6d 100%); */
    /* background-color : #FFA500; */
    background-color: var(--secondary-color);
    /* background-color: #494848; */
    position: fixed;
    width: 100%;
    max-width: 480px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99;
    border-radius: 0 0 10px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 10px;
}
.typeName {
    font-size: 14px;
}
.menu {
    padding: 0;
    width: 38px;
    height: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    line-height: 46px;
    border-radius: 2px;
}
.backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #00000066;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.sidebar {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #FFF;
    overflow: hidden;
}
.sidebarHeader {
    position: inherit;
    top: 0;
    height: 100px;
    background-color: #212121;
    width: 300px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 20px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.userIcon {
    display: block;
    width: 35px;
    height: 35px;
    background-color: #FFF;
    border-radius: 25px;
    color: var(--secondary-color);
    text-align: center;
    line-height: 35px;
    font-size: 15px;
}

.userCode {
    font-size: 15px;
    margin-left: 15px;
}

.sidebarContent {
    padding-top: 100px;
    width: 300px;
    overflow: hidden;
}
.sidebarFooter {
    width: 300px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
}
.sidebarContent li,.sidebarFooter li {
    padding: 12px 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    cursor: pointer;
}
.sidebarContent li:hover, .sidebarFooter li:hover {
    background-color: #e6e6e6;
}
.contentText {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.contentText p,.contentFooterText p {
    font-size: 12px;
    margin-left: 10px;
    color: #5b5b5b;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.contentFooterText {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
}
.back {
    padding: 0;
    width: 40px;
    height: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    line-height: 46px;
    background-color: transparent;
    color: #FFF;
}

.logoContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
}
.headerIconBtn {
    border: none;
    border-radius: 50%;
    background-color: transparent;
    color: #FFF;
    cursor: pointer;
}
.backNormal {
    padding: 0;
    width: 60px;
    height: 40px;
    outline: none;
    border: none;
    cursor: pointer;
    line-height: 55px;
    text-align: center;
    background-color: #ffa500b8;
    color: #FFF;
    border-radius: 20px;
    margin-right: 10px;
}
.logoContainer img {
    width: 135px;
    margin-top: 6px;
}
.userInfo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
}
.userInfo p {
    font-size: 14px;
    margin-right: 10px;
    text-align: right;
}
.userInfo p:first-child {
    margin-bottom: 5px;
}
.userInfo span {
    border: 2px solid var(--accent-color);
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    border-radius: 50%;
}