.matchs li {
    margin-bottom: 10px;
}
.matchs li:last-child {
    margin-bottom: 0;
}
.card {
    width: 100%;
    /* background-color: #a09f9f; */
    background-color: #ffffff33;
    border-radius: 5px;
    padding: 7px;
    margin-top: 5px;
}
.leagueName {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.leagueName span {
    font-size: 13px;
    margin-left: 5px;
    font-weight: bolder !important;
}
.teamLogoBg {
    /* width: 25px;
    height: 25px; */
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #FFF;
    padding: 2px;
    box-shadow: 0px 0px 3px #6d6d6d;

}
.teamH img, .teamA img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%); */
}
.teamH .teamLogoBg {
    right: 5px;
}
.teamA .teamLogoBg {
    left: 5px;
}
.start_time {
    font-size: 12px;
    color: #FFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /* color: #616161; */
}
.team_container {
    margin-top: 3px;
}
.ou_container {
    /* margin-top: 3px; */
    border-top: 1px solid #a09f9f;
}
.hdp_container, .ou_container, .team_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.hdp_container div {
    width: 100%;
    height: 30px;
    background-color: #616161;
    position: relative;
    word-break: break-all;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #FFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.hdp_container div:first-child {
    border-radius: 3px 0 0 0;
}
.hdp_container div:nth-child(2) {
    border-radius: 0 3px 0 0;
}
.team_container div {
    width: 100%;
    height: 35px;
    /* height: 40px; */
    background-color: #000626;
    position: relative;
    word-break: break-all;
    font-size: 12px;
    color: var(--accent-color);
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.teamH {
    padding-right: 42px;
    padding-left: 5px;
    border-radius: 3px 0 0 3px;
    text-align: end;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.teamA {
    padding-left: 42px;
    padding-right: 5px;
    border-radius: 0 3px 3px 0;
    text-align: start;
    word-break: break-all;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.home {
    /* margin-right: 1.5px; */
    /* padding-right: 55px; */
    border-right: 1px solid #a09f9f;;
}
.away {
    /* margin-left: 1.5px; */
    /* padding-left: 55px; */
}

.home_odds,.away_odds {
    min-width: 40px;
    max-width: 40px;
    height: 25px;
    background-color: var(--accent-color);
    font-size: 12px;
    text-align: center;
    line-height: 25px;
    display: block;
    border-radius: 5px;
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%); */
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #000626;
}
.home_odds {
    /* right: 5px; */
    margin-left: 5px;
}
.away_odds {
    /* left: 5px; */
    margin-left: 5px;
}
.ou_odds {
    min-width: 40px;
    max-width: 40px;
    height: 30px;
    background-color: #616161;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.ou_odds div{
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 30px;
    color: #000626;
    background-color: var(--accent-color);
    border-radius: 5px;
    font-size: 12px;
}

.over,.under {
    width: 100%;
    height: 30px;
    background-color: #616161;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #FFF;
}
.over {
    border-radius: 0 0 0 3px;
}
.under {
    border-radius: 0 0 3px 0;
}
.betFlex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 3px;
}
.type {
    max-width: 50px;
    min-width: 50px;
    background-color: #000626;
    height: 63px;
    margin-right: 3px;
    border-radius: 3px;
    text-align: center;
    line-height: 63px;
    font-size: 14px;
    font-weight: bolder;
    letter-spacing: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #FFF;
}
.evenOdd {
    margin-top: 3px;
}
.evenOddType {
    max-width: 50px;
    min-width: 50px;
    background-color: #000626;
    height: 30px;
    margin-right: 3px;
    border-radius: 3px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    font-weight: bolder;
    letter-spacing: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #FFF;
}

.correctScoresContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding-top: 5px;
}
.correctScoresButtonContainer {
    width: 25%;
    padding: 0 2.5px 5px 2.5px;
}
.correctScoresOddsContainer {
    width: 100%;
    cursor: pointer;
}
.correctScoresOdds {
    text-align: center;
    font-size: 13px;
    font-weight: bolder;
    padding: 8px 2.5px;
    color: #FFF;
    border-radius: 5px 5px 0 0;
}
.correctScoresResult {
    text-align: center;
    padding: 5px 2.5px;
    /* background-color: #23273b; */
    background-color: #000626;
    border-radius: 0 0 5px 5px;
    font-size: 12px;
    font-weight: bolder;
}