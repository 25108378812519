@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Playwrite+AU+QLD:wght@300&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

:root {
  --main-color: #000c46;
  --accent-color: #D18700;
  --secondary-color: #303030;
  --text-color: #ffce00;
}
html, body {
  scroll-behavior: smooth;
  overflow: hidden auto;
}
body {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  color: #fff;
  background-color: #000c46;
  /* background: url('./assets/images/background/body.jpg') center no-repeat;
  background-size: cover; */
  position: relative;
}

/* body::after {
  content: '';
  background-color: #0000004f;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

ul, li {
  list-style-type: none;
};

a {
  text-decoration: none;
  color: #f5f5f5;
}

select {
  -webkit-appearance: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #fff;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: 0 none;
  caret-color: #fff;
} 

.react-ripples {
  width: 100%;
  height: 100%;
}
.btn .react-ripples {
  border-radius: 50% !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}
/* .ant-drawer-content-wrapper {
  box-shadow: none !important;
} */
.ant-checkbox-group > label {
  margin-bottom: 5px !important;
  outline: none !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--accent-color) !important;
  border-color: var(--accent-color) !important;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--accent-color) !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: var(--accent-color) !important;
}

.ant-input-outlined:hover,
.ant-input-outlined:focus, .ant-input-outlined:focus-within {
  border-color: var(--accent-color) !important;
}
.ant-input-affix-wrapper {
  padding: 7px 11px !important;
  font-size: 16px !important;
}

.ant-collapse-expand-icon {
  -webkit-padding-end: 0 !important;
     -moz-padding-end: 0 !important;
          padding-inline-end: 0 !important;
}
.ant-collapse-content-box {
  padding: 0 !important;
}
.ant-collapse-header {
  color: var(--accent-color) !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}
.ant-collapse-header {
  padding: 3px 0 !important;
}
.ant-drawer-title,.ant-drawer-body label {
  color: #FFF !important;
}
.ant-drawer-body,.ant-drawer-header {
  padding: 16px 17px !important
}


.date-picker-container .ant-picker-input,.wallet-transaction-date-picker-container .ant-picker-input {
  display: none !important;
}

.date-picker-container .ant-picker {
  width: 40px !important;
  height: 30px !important;
  background: url('./assets/images/icons/calendar.png') center no-repeat;
  background-size: contain;
  border: transparent !important;
}

.wallet-transaction-date-picker-container .ant-picker {
  width: 30px !important;
  height: 30px !important;
  background: var(--calendar-icon-url) center no-repeat;
  background-size: contain;
  border: transparent !important;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}


.custom-password-input {
  padding: 10px 15px !important;
  font-size: 15px !important;
  background-color: #d187006b !important;
  border-color: transparent !important;
  color: #FFF !important;
  border-radius: 12px !important;
}
.custom-password-input {
  padding: 10px 15px;
  font-size: 15px;
  background-color: #d187006b !important;
  border-color: transparent;
  color: #FFF;
  border-radius: 12px !important;
  letter-spacing: 1px !important;
}
.custom-password-input .ant-input::-webkit-input-placeholder {
  color: #d1880097;
}
.custom-password-input .ant-input::-moz-placeholder {
  color: #d1880097;
}
.custom-password-input .ant-input:-ms-input-placeholder {
  color: #d1880097;
}
.custom-password-input .ant-input::placeholder {
  color: #d1880097;
}
.custom-password-input .ant-input:-ms-input-placeholder {
  color: #d1880097;
}
.custom-password-input .ant-input::-ms-input-placeholder {
  color: #d1880097;
}
.custom-password-input span {
  color: #d1880097 !important;
}

.orange-radio .ant-radio-checked .ant-radio-inner {
  border-color: var(--accent-color) !important;
  background-color: var(--accent-color) !important;
}

.orange-radio:hover .ant-radio-inner {
  border-color: var(--accent-color) !important;
}

.someRemarkContainer .ant-select-selection-search-input {
  border-radius: 0 !important;
  font-size: 12px !important;
}
 .ant-select-item,.ant-select-selector {
  font-size: 12px !important;
}
.someRemarkContainer .ant-select-selection-search-input::-webkit-input-placeholder {
   color: #ffffff5a;
}
.someRemarkContainer .ant-select-selection-search-input::-moz-placeholder {
   color: #ffffff5a;
}
.someRemarkContainer .ant-select-selection-search-input:-ms-input-placeholder {
   color: #ffffff5a;
}
.someRemarkContainer .ant-select-selection-search-input::placeholder {
   color: #ffffff5a;
}
.ant-select-dropdown {
  background-color: #1f2338 !important;
  border-radius: 5px !important;
}
.ant-select-item-option-content {
  color: #FFF !important;
}
.ant-select-item-option-active:hover {
  background-color: #ffffff2c !important;
}

.profile-modal {
  color: #FFF;
}

.profile-modal .ant-modal-content {
  background-color: #2f3950; /* Change this to your desired color */
}

.profile-modal .ant-btn-default {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  color: #FFF;
  height: 48px;
  width: 100%;
  border-radius: 30px;
}
.profile-modal .ant-btn-default:hover {
  background-color: var(--accent-color) !important;
  color: #FFF !important;
  border-color: var(--accent-color) !important;
}

.live-scores-types .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: red !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--accent-color) !important;
}
.live-scores-types .ant-select-selector {
  background-color: var(--accent-color) !important;
  border-color: var(--accent-color) !important;
  color: #FFF;
  border-radius: 20px;
}
.live-scores-types .ant-select-selection-item {
  color: #FFF !important;
}
.live-scores-types .ant-select-arrow {
  color: #FFF !important;
}
.dateRangeExtra {
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 50;
}