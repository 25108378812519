.tabs {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #ffffff21;
    padding-bottom: 5px;
}
.tabs li {
    width: 14.3%;
    padding: 5px 0;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.tabs li div {
    text-align: center;
    font-size: 12px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* padding-bottom: 2px; */
}
.active {
    border-bottom: 2px solid var(--accent-color) !important;
    color: var(--accent-color);
}
.typeTabs {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
    overflow: auto hidden;
}
.typeTabs li {
    background-color: #ffffff33;
    font-size: 12px;
    margin-right: 7px;
    border-radius: 20px;
    min-width: 100px;
    max-width: 100px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.typeTabs li div svg {
    color: var(--accent-color);
}
.typeTabs li:last-child {
    margin-right: 0;
}
.activeType {
    background-color: var(--accent-color) !important;
}
.activeType div svg {
    color: #FFF !important; 
}

@media (min-width: 200px) and (max-width: 350px) {
    .tabs li div {
        font-size: 10px;
    }
 }