.historyContainer {
    height: 100%;
    overflow: hidden scroll;
}
.passenger {
    margin-bottom: 10px;
}
.historyContainer .passenger:last-child {
    margin-bottom: 0;
}
.passenger-ticket {
    width:100%;
    padding: 10px;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    background:#333852;
    position:relative;
    border-bottom: 2px dashed #000626;
}
.passenger .passenger-ticket:last-child {
    border-bottom:none;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
}
.passenger .passenger-ticket:last-child .circle,.passenger .passenger-ticket:last-child .ticket-bottom {
    display:none;
}

.passenger-ticket__bottom {
    position:absolute;
    width:100%;
    bottom:6px;
    left:0;
}
.circle{
    width:14px;
    height:14px;
    border-radius:50%;
    position:absolute;
    background: #000626;
    z-index:2;
}
.circle-left {
    left:-7px;
}
.circle-right {
    right:-7px;
}
.team_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.team_container div {
    width: 100%;
    height: 50px;
    position: relative;
    word-break: break-all;
    font-size: 15px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.teamH {
    padding-right: 52px;
    padding-left: 5px;
    border-radius: 3px 0 0 3px;
    text-align: end;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.teamA {
    padding-left: 52px;
    padding-right: 5px;
    border-radius: 0 3px 3px 0;
    text-align: start;
    word-break: break-all;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.teamLogoBg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #FFF;
    padding: 2px;
    box-shadow: 0px 0px 3px #6d6d6d;

}
.teamH img, .teamA img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%); */
}
.teamH .teamLogoBg {
    right: 5px;
}
.teamA .teamLogoBg {
    left: 5px;
}
.remarkText {
    /* faad14 */
    color: #ffffffac;
    width: 100%;
    text-align: center;
    font-family: "Playwrite AU QLD", cursive;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.odds_badge {
    min-width: 40px;
    max-width: 40px;
    height: 25px;
    background-color: var(--accent-color);
    font-size: 12px;
    text-align: center;
    line-height: 25px;
    display: block;
    border-radius: 5px;
    margin-right: 5px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #000626;
}

.typeIconImg {
    width: 50px;
    margin-bottom: 5px;
}
.paymentIconContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.payment_success {
    font-size: 13px;
    margin-bottom: 5px;
    color: #ffffff99;
}
.payment_amount {
    font-size: 30px;
    margin-bottom: 5px;
    font-weight: 100;
}